import { DataGrid } from 'components/tables';
import { useTranslation } from 'react-i18next';
import { Dialog, Grid } from '@mui/material';
import RemovedAssetIcon from 'components/Icons/removed-asset';
import { formatDate } from 'utils';
import { capitalize } from 'lodash';
import { useContext, useState, useEffect } from 'react';
import { SourcesContext } from 'context';
import {
  useParams,
  useNavigate,
  useLocation,
  createSearchParams,
} from 'react-router-dom';
import qs from 'qs';
import AddSource from 'routes/parameters-page/common/add-source';
import GlobalSourcesTable from 'routes/sources-page/global-sources-table';
import { grey } from '@mui/material/colors';
import useSourceTypes from './hooks/use-source-types';

function SourcesTable() {
  const navigate = useNavigate();
  const {
    allSources,
    isLoading,
    rowSelectionModel,
    setRowSelectionModel,
    setOpenEditingForm,
    openAddSource,
    setOpenAddSource,
    openGlobalSources,
    setOpenGlobalSources,
    setNewSource,
  } = useContext(SourcesContext);

  const [dataGridHeight, setDataGridHeight] = useState(0);
  const { sourceTypesResponse } = useSourceTypes();
  const { t, i18n } = useTranslation(['sources']);
  const { projectId } = useParams();
  const { search } = useLocation();
  const searchString = { ...qs.parse(search, { ignoreQueryPrefix: true }) };

  const sourceTypeNames = () => {
    const availableSourceTypes = sourceTypesResponse.map(
      sourceType => sourceType.name
    );
    if (!availableSourceTypes.includes('Auto')) {
      availableSourceTypes.push('Auto');
      availableSourceTypes?.sort((a, b) => {
        return a.localeCompare(b);
      });
    }
    return availableSourceTypes;
  };

  const sourcesColumns = [
    {
      flex: 0.35,
      field: 'title',
      headerName: 'Source Name',
      type: 'string',
      hideable: false,
      valueGetter: ({ value }) => capitalize(value),
      renderCell: ({ value, row }) => {
        return row.deletedAt !== null ? (
          <Grid container>
            <RemovedAssetIcon
              sx={{
                color: theme => theme.palette.secondary.dark,
                marginRight: theme => theme.spacing(0.75),
              }}
            />
            {capitalize(value)}
          </Grid>
        ) : (
          capitalize(value)
        );
      },
    },
    {
      flex: 0.2,
      field: 'reference',
      headerName: 'Reference',
      hideable: true,
      valueGetter: ({ value }) => value,
    },
    {
      flex: 0.2,
      field: 'sourceType',
      headerName: 'Source Type',
      hideable: true,
      type: 'singleSelect',
      valueOptions: () => sourceTypeNames(),
      valueGetter: ({ value }) => value.name,
    },
    {
      flex: 0.15,
      field: 'dateDay',
      headerName: 'Publication/Revision',
      hideable: true,
      valueGetter: params => {
        if (
          params.row.dateDay === null ||
          params.row.dateMonth === null ||
          params.row.dateYear === null
        ) {
          return t('common:invalidDate');
        }
        return `${formatDate(
          `${params.row.dateYear}-${
            params.row.dateMonth > 9
              ? params.row.dateMonth
              : `0${params.row.dateMonth}`
          }-${params.row.dateDay}`,
          undefined,
          i18n.language
        )}`;
      },
    },
    {
      flex: 0.15,
      field: 'createdAt',
      type: 'date',
      headerName: 'Source created',
      hideable: true,
      valueGetter: ({ value }) => new Date(value),
      renderCell: ({ value }) => {
        return formatDate(value, undefined, i18n.language);
      },
    },
    {
      flex: 0.15,
      field: 'updatedAt',
      type: 'date',
      headerName: 'Last modified',
      hideable: true,
      valueGetter: ({ value }) => new Date(value),
      renderCell: ({ value }) => {
        return formatDate(value, undefined, i18n.language);
      },
    },
  ];

  useEffect(() => {
    const updateHeight = () => {
      setDataGridHeight(window.innerHeight - 200);
    };

    updateHeight();

    // Update height on window resize
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <>
      <DataGrid
        data-cy="sourcesGrid"
        sx={{
          marginTop: theme => theme.spacing(0.5),
          marginLeft: theme => theme.spacing(2),
          marginRight: theme => theme.spacing(2),
          '.deleted-source': {
            color: theme => theme.palette.secondary.dark,
            backgroundColor: theme => {
              return theme.palette.mode === 'dark' ? grey[700] : grey[400];
            },
          },
        }}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={newRowSelectionModel => {
          navigate({
            pathname: `/projects/${projectId}/sources/${newRowSelectionModel[0]}`,
            search: `?${createSearchParams({ ...searchString })}`,
          });
          setOpenEditingForm(false);
          setRowSelectionModel(newRowSelectionModel);
        }}
        disableMultipleRowSelection
        loading={isLoading}
        density="standard"
        columns={sourcesColumns}
        rows={allSources}
        rowHeight={32}
        height={dataGridHeight}
        aria-label="sources-management-table"
        noRowsMessage="data-grid:noSources"
        columnBuffer={7}
        searchToolbar
        getRowClassName={params => {
          return params.row.deletedAt !== null ? 'deleted-source' : '';
        }}
      />
      <Dialog
        data-testid="source-dialog"
        open={openAddSource}
        onClose={() => {
          setOpenAddSource(false);
        }}
      >
        <AddSource
          openAddSource={openAddSource}
          setOpenAddSource={setOpenAddSource}
          setSelectedSource={setNewSource}
        />
      </Dialog>
      <Dialog
        data-testid="source-dialog"
        open={openGlobalSources}
        onClose={() => {
          setOpenGlobalSources(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <GlobalSourcesTable />
      </Dialog>
    </>
  );
}

export default SourcesTable;
